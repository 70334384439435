@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + ".woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@include font("AeonikRegular", "../fonts/Aeonik-Regular");
