/* make the customizations */
$primary: #21cc6e;
$primary-dark: #0bb156;
$black: #050505;
$grey: #cccccc;

html,
body {
  font-size: 18px;
  font-family: "AeonikRegular" !important;
}
$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136,
);

.arrowsLeft {
  background-image: url(../img/3Dctrl_Arrow_left.svg);
  width: 21px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px auto;
}

.arrowsRight {
  background-image: url(../img/3Dctrl_Arrow_right.svg);
  width: 21px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px auto;
}

%footericons {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 17px;
}
.instagramico {
  @extend %footericons;
  background-image: url(../img/3Dctrl_Social_IG_1.svg);
}
.twitterico {
  @extend %footericons;
  background-image: url(../img/3Dctrl_Social_TW_1.svg);
}
.linkedinico {
  @extend %footericons;
  background-image: url(../img/3Dctrl_Social_LI_1.svg);
}
.mediumico {
  @extend %footericons;
  background-image: url(../img/3Dctrl_Social_MD_1.svg);
}
.vimeoico {
  @extend %footericons;
  background-image: url(../img/3Dctrl_Social_VM_1.svg);
}
.navbar-toggler {
  border: none !important;
  .navbar-toggler-icon {
    background-image: url(../img/Burger.svg) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
