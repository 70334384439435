%btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 188px;
  height: 49px;
  border: 0px solid transparent;
  border-radius: 25px;
  font-family: "AeonikRegular";
  text-decoration: none;
  text-transform: lowercase;
}

.btn-primary {
  @extend %btn;
  background-color: $primary;
  color: $black;

  &:hover {
    transition: 0.2s ease-in-out;
    background-color: $primary-dark;
    color: $black;
  }
  &:active {
    transition: 0.2s ease-in-out;
    background-color: $primary-dark;
    color: $black;
  }
}
.btn-secondary {
  @extend %btn;
  background-color: transparent;
  border: 1px solid $primary;
  color: $black;

  &:hover {
    transition: 0.2s ease-in-out;
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
    color: $black;
  }
  &:active {
    transition: 0.2s ease-in-out;
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
    color: $black;
  }
}
